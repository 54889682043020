import enforceSequentiality from '../enforceSequentiality'
import {toMonitored, log} from '../../utils/monitoring'
import * as state from '../services/applicationState'
import {isApplicationReady, getPageDependencies} from '../applicationState'
import {addApplications} from '../platform-api/addApplications'
import {removeMembersAreaPage} from '../platform-api/removeMembersAreaPage'
import {asyncFilter} from '../../utils/promises'
import {IntegrationApplication} from '@wix/members-area-integration-kit'
import {createBIService} from '../../utils/bi'
import {isMyWalletEnabled, isMyWalletApp} from './myWalletIntegration'

interface IntegratedAppsMap {
    [appDefId: string]: IntegrationApplication[]
}
const isAddApplication = (app: IntegrationApplication) => app.method === 'addApplication'
const hasNoDependencies = (allApps: IntegrationApplication[]) => (app: IntegrationApplication) => !allApps.some((app2) => app2.pageId === app.pageId)
const hasNoLegacyDependencies = (legacyDependantApps) => (app: IntegrationApplication) => !legacyDependantApps[app.appDefinitionId]?.size
const isAppInstalled = (editorSDK) => (appDefinitionId) => editorSDK.document.tpa.isApplicationInstalled('', {appDefinitionId})

const maybeAddApplications = async (applications: IntegrationApplication[], editorSDK, appToken = '') => {
    const isReady = await isApplicationReady(editorSDK, appToken)
    if (!isReady) {
        console.warn('Members Area installation was corrupted so the integrations pages will not be added')
        log('Skipping addApplications as the application is not ready and probably already deleted')
        return
    }
    const forceHorizontalLayout = false
    return toMonitored('editorApi.addApplications', () => addApplications({editorSDK, appToken, applications, forceHorizontalLayout}))
}

const removePage = (pageId: string, editorSDK, appToken = '') => toMonitored('editorApi.removeMembersAreaPage', () => removeMembersAreaPage({editorSDK, appToken, pageId}))

export const registerMembersAreaApps = (applications: IntegrationApplication[], verticalAppDefId: string) =>
    toMonitored('editorApi.registerMembersAreaApps', () => {
        const currentIntegratedAppsMap = state.getAllIntegratedApps()
        state.setIntegratedApps({
            ...currentIntegratedAppsMap,
            [verticalAppDefId]: applications
        })
    })



export const installRegisteredApps = async (verticalAppDefId: string, editorSDK) => {
    const biService = await createBIService({editorSDK})
    biService.verticalTriggeredMaInstallInitiated({originAppId: verticalAppDefId})
    return enforceSequentiality(() =>
        toMonitored('editorApi.installRegisteredApps', async () => {
            const integrationApps: IntegrationApplication[] = state.getVerticalsApps(verticalAppDefId)
            const integrationAppsToInstall = await asyncFilter(
                integrationApps.filter((app) => app.shouldInstallInitially !== false),
                (app) => (isMyWalletApp(app) ? isMyWalletEnabled() : true)
            )

            if (integrationAppsToInstall.length > 0) {
                await maybeAddApplications(integrationAppsToInstall, editorSDK)
            }
            biService.verticalTriggeredMaInstallSuccess({originAppId: verticalAppDefId})
        })
    )
}

export const handleVerticalDeletion = (verticalAppDefId: string, editorSDK) =>
    enforceSequentiality(() =>
        toMonitored('editorApi.handleVerticalDeletion', async () => {
            const verticalsApps: IntegrationApplication[] = state.getVerticalsApps(verticalAppDefId)
            const allIntegratedAppsMap: IntegratedAppsMap = state.getAllIntegratedApps()
            const installedVerticalIds: string[] = await asyncFilter(Object.keys(allIntegratedAppsMap), isAppInstalled(editorSDK))
            const appsOfOtherVerticals: IntegrationApplication[] = installedVerticalIds.reduce((acc, appDefId) => acc.concat(Object.values(allIntegratedAppsMap[appDefId])), [])
            const legacyDependantApps = getPageDependencies()
            const integratedAppsToDelete = verticalsApps
                .filter(hasNoDependencies(appsOfOtherVerticals))
                .filter(isAddApplication)
                .filter(hasNoLegacyDependencies(legacyDependantApps))

            for (const app of integratedAppsToDelete) {
                await removePage(app.pageId, editorSDK)
            }
        })
    )

export const getRegisteredApps = (editorSDK) =>
    enforceSequentiality(() =>
        toMonitored('editorApi.getRegisteredApps', async () => {
            const allIntegratedAppsMap: IntegratedAppsMap = state.getAllIntegratedApps()
            const registeredVerticalIds = Object.keys(allIntegratedAppsMap)
            const installedVerticalIds: string[] = await asyncFilter(registeredVerticalIds, isAppInstalled(editorSDK))
            const installedPagesIds = (await editorSDK.pages.getApplicationPages()).map((pageData: {tpaPageId: any}) => pageData.tpaPageId)
            const filteredAppsMap: IntegratedAppsMap = installedVerticalIds.reduce((acc, appDefId) => {
                const notInstalledPages = allIntegratedAppsMap[appDefId]?.filter((app) => !installedPagesIds.includes(app.pageId)) ?? []
                return {...acc, [appDefId]: notInstalledPages}
            }, {})

            return filteredAppsMap
        })
    )
