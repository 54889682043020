import {instanceValue} from '@wix/wix-instance-parser-js/dist/src'
import webBiLogger from '@wix/web-bi-logger'
import initSchemaLogger, {
    loadMembersCustomPagesParams,
    addPageButtonClickMembersPagesParams,
    menuToggleParams,
    verticalTriggeredMaInstallInitiatedParams,
    verticalTriggeredMaInstallSuccessParams,
    maPageInstalledParams } from '@wix/bi-logger-members-app-users'
import {SANTA_MEMBERS_APP_ID} from '../editor-app-module/constants'

type BIService = ReturnType<typeof createBIService>

const createBIService = async ({editorSDK}) => {
    const instance = await editorSDK.info.getAppInstance()
    const instanceId = instanceValue.getValueFromInstance('instanceId', instance)
    const biToken = instanceValue.getValueFromInstance('biToken', instance)

    const baseProps = {app_id: SANTA_MEMBERS_APP_ID, instance_id: instanceId, biToken}

    const logger = initSchemaLogger(webBiLogger)()

    const logMembersMenuToggle = ({pageId, pageName, toggle_status, is_social}) => {
        const toggleName = 'Members Menu'
        const props = {...baseProps, pageId, pageName, toggleName, toggle_status, is_social}
        return logger.menuToggle(props)
    }

    const logLoginMenuToggle = ({pageId, pageName, toggle_status, is_social}: Partial<menuToggleParams>) => {
        const toggleName = 'Login Menu'
        const props = {...baseProps, pageId, pageName, toggleName, toggle_status, is_social}
        return logger.menuToggle(props)
    }

    const logInstallationFailure = (errorMsg: string) => {
        const props = {...baseProps, errorMsg, appInstanceId: baseProps.app_id};
        delete props.app_id
        return logger.membersAreaInstallationError(props)
    }

    const addPageButtonClick = ({origin, pageName, pageId}: Partial<addPageButtonClickMembersPagesParams>) => {
        return logger.addPageButtonClickMembersPages({...baseProps, origin, pageName, pageId});
    }

    const loadMembersCustomPages = ({origin, pageName, pageId, referralInfo}: Partial<loadMembersCustomPagesParams>) => {
        return logger.loadMembersCustomPages({...baseProps, origin, pageName, referralInfo, pageId})
    }

    const verticalTriggeredMaInstallInitiated = ({originAppId}: Partial<verticalTriggeredMaInstallInitiatedParams>) => {
        return logger.verticalTriggeredMaInstallInitiated({...baseProps, originAppId})
    }

    const verticalTriggeredMaInstallSuccess = ({originAppId}: Partial<verticalTriggeredMaInstallSuccessParams>) => {
        return logger.verticalTriggeredMaInstallSuccess({...baseProps, originAppId})
    }

    const maPageInstalled = ({originAppId, pageName}: Partial<maPageInstalledParams>) => {
        return logger.maPageInstalled({...baseProps, originAppId, pageName})
    }

    const managePagesActionDelete = ({pageId, pageName, value}) => {
        return logger.managePagesActionDelete({...baseProps, pageId, pageName, value});
    }

    const managePagesAddMemberPagesClick = ({origin}) => {
        return logger.managePagesAddMemberPagesClick({...baseProps, origin});
    }

    const managesPagesModalTabNavigation = ({origin, tabName}) => {
        return logger.managesPagesModalTabNavigation({...baseProps, origin, tabName});
    }

    return {
        logMembersMenuToggle,
        logLoginMenuToggle,
        logInstallationFailure,
        addPageButtonClick,
        loadMembersCustomPages,
        verticalTriggeredMaInstallInitiated,
        verticalTriggeredMaInstallSuccess,
        maPageInstalled,
        managePagesActionDelete,
        managePagesAddMemberPagesClick,
        managesPagesModalTabNavigation
    }
}

export {createBIService, BIService}
