import {MENU_IDS} from '../constants'
import {assign as assign_, merge as merge_, get as get_, find as find_} from 'lodash'

const BASIC_MENU_ITEM = {type: 'BasicMenuItem', items: [], isVisible: true, isVisibleMobile: true}
const {SUB_MENU_ID, LOGIN_MENU_ID, LOGIN_ICONS_MENU_ID} = MENU_IDS

async function create(editorSDK, appToken) {
    await removeMenus(editorSDK, appToken)
    const membersMenuIdPromise = editorSDK.menu.create(appToken, {menuData: {name: 'Member Menu', items: []}, customId: SUB_MENU_ID})
    const loginMenuIdPromise = editorSDK.menu.create(appToken, {menuData: {name: 'Login Menu', items: []}, customId: LOGIN_MENU_ID})
    const loginIconsMenuIdPromise = editorSDK.menu.create(appToken, {menuData: {name: 'Login Icons', items: []}, customId: LOGIN_ICONS_MENU_ID})

    const [members, login, icons] = await Promise.all([
        membersMenuIdPromise.catch(() => SUB_MENU_ID),
        loginMenuIdPromise.catch(() => LOGIN_MENU_ID),
        loginIconsMenuIdPromise.catch(() => LOGIN_ICONS_MENU_ID)
    ])

    return {members, login, icons}
}

async function removeMenuItems(editorSDK, appToken, menuId) {
    const menuData = await editorSDK.menu.getById(appToken, {menuId})
    menuData.items = []
    await editorSDK.menu.update(appToken, {menuId, menuData})
}

async function removeMenuItem(editorSDK, appToken, menuId, innerRoute) {
    const menuData = await editorSDK.menu.getById(appToken, {menuId})
    menuData.items = menuData.items.filter(item => item.link.innerRoute !== innerRoute)
    return await editorSDK.menu.update(appToken, {menuId, menuData})
}

async function removeMenus(editorSDK, appToken) {
    const [subMenuExists, logInMenuExists, logInIconsMenuExists] = await Promise.all([
        editorSDK.menu.getById(appToken, {menuId: SUB_MENU_ID}),
        editorSDK.menu.getById(appToken, {menuId: LOGIN_MENU_ID}),
        editorSDK.menu.getById(appToken, {menuId: LOGIN_ICONS_MENU_ID})
    ])

    const menuItemsPromises = [
        subMenuExists && removeMenuItems(editorSDK, appToken, SUB_MENU_ID),
        logInMenuExists && removeMenuItems(editorSDK, appToken, LOGIN_MENU_ID),
        logInIconsMenuExists && removeMenuItems(editorSDK, appToken, LOGIN_ICONS_MENU_ID),
    ].filter(p => !!p)

    await Promise.all(menuItemsPromises)

    // Menus must be removed after finishing the delete of the menu items
    const menusPromises = [
        subMenuExists && editorSDK.menu.remove(appToken, {menuId: SUB_MENU_ID}),
        logInMenuExists && editorSDK.menu.remove(appToken, {menuId: LOGIN_MENU_ID}),
        logInIconsMenuExists && editorSDK.menu.remove(appToken, {menuId: LOGIN_ICONS_MENU_ID}),
    ].filter(p => !!p)

    await Promise.all(menusPromises)
}

function createNewMenuItem(linkData) {
    return assign_({}, BASIC_MENU_ITEM, linkData)
}

function getMenuIds() {
    return {
        members: SUB_MENU_ID,
        login: LOGIN_MENU_ID,
        icons: LOGIN_ICONS_MENU_ID
    }
}

async function updateMenuItemInAllMenus({editorSDK, appToken, pageRouterData, updatedData}) {
    const menuIds = getMenuIds()
    const updateMembersMenu = updateMenuItem({editorSDK, appToken, pageRouterData, updatedData, menuId: menuIds.members})
    const updateLoginMenu = updateMenuItem({editorSDK, appToken, pageRouterData, updatedData, menuId: menuIds.login})
    const updateIconsMenu = updateMenuItem({editorSDK, appToken, pageRouterData, updatedData, menuId: menuIds.icons})

    return Promise.all([
        updateMembersMenu,
        updateLoginMenu,
        updateIconsMenu,
    ])
}

async function updateMenuItem({editorSDK, appToken, pageRouterData, updatedData, menuId}) {
    const innerRoute = pageRouterData.innerRoute
    const menuData = await editorSDK.menu.getById(appToken, {menuId})
    const menuItem = menuData.items.find(item => '/' + item.link.innerRoute === innerRoute)

    if (menuItem) {
        merge_(menuItem, updatedData)
        await editorSDK.menu.update(appToken, {menuId, menuData})
    }
}

async function getMenuItemByRoute({editorSDK, appToken, menuId, innerRoute}) {
    const menuData = await editorSDK.menu.getById(appToken, {menuId})
    const menuItem = menuData && menuData.items && menuData.items.find(item => '/' + item.link.innerRoute === innerRoute)
    return menuItem
}

async function getMenuItems({editorSDK, appToken, menuId}) {
    const menu = await editorSDK.menu.getById(appToken, {menuId})

    if (!menu) {
        throw new Error('Could not retrieve the menu ' + menuId)
    }

    return menu.items
}

function getMenuById({editorSDK, appToken, menuId}) {
    return editorSDK.menu.getById(appToken, {menuId})
}

async function removePatternFromMenu({editorSDK, appToken, menuId, pattern}) {
    const menuData = await editorSDK.menu.getById(appToken, {menuId})
    const newMenuItems = []
    for (let item of menuData.items) {
        if (item.link.innerRoute !== pattern) {
            newMenuItems.push(item)
        }
    }
    menuData.items = newMenuItems
    await editorSDK.menu.update(appToken, {menuId, menuData})
}

async function removePatternFromAllMenus({editorSDK, appToken, pattern}) {
    const menuIds = getMenuIds()
    for (let menuId of Object.values(menuIds)) {
        await removePatternFromMenu({editorSDK, appToken, menuId, pattern})
    }
}

async function orderLoginMenuItems({editorSDK, appToken}) {
    const menuIds = getMenuIds()

    const membersMenuItems = await getMenuItems({editorSDK, appToken, menuId: menuIds.members})
    const loginMenuItems = await getMenuItems({editorSDK, appToken, menuId: menuIds.login})

    // Comparing by routes as they are unique
    const membersMenuItemsInnerRoutes = membersMenuItems.map(item => item.link.innerRoute)
    const loginMenuItemsInnerRoutes = loginMenuItems.map(item => item.link.innerRoute)

    const reorderedLoginMenuItemsRoutes = membersMenuItemsInnerRoutes.filter(route => loginMenuItemsInnerRoutes.indexOf(route) > -1)
    const reorderedLoginMenuItems = reorderedLoginMenuItemsRoutes.map(route => loginMenuItems.find(item => item.link.innerRoute === route))

    return await updateMenuItems({editorSDK, appToken, menuId: menuIds.login, items: reorderedLoginMenuItems})
}

async function updateMenuItems({editorSDK, appToken, menuId, items}) {
    const menuData = await editorSDK.menu.getById(appToken, {menuId})
    menuData.items = items
    await editorSDK.menu.update(appToken, {menuId, menuData})
}

export {
    createNewMenuItem,
    create,
    getMenuById,
    getMenuIds,
    getMenuItemByRoute,
    getMenuItems,
    removeMenus,
    removeMenuItem,
    removePatternFromAllMenus,
    updateMenuItem,
    updateMenuItemInAllMenus,
    updateMenuItems,
    orderLoginMenuItems,
}
