import * as history from '../wrappers/history'
import {isEqual as isEqual_} from 'lodash'
import * as membersLogic from '../membersLogic'
import * as memberPages from '../pages'
import * as menus from '../wrappers/menus'
import * as routers from '../wrappers/routers'
import * as pages from '../wrappers/pages'
import enforceSequentiality from '../enforceSequentiality'
import {PERMISSION_STATES} from '../constants'
import {toMonitored} from '../../utils/monitoring'

export default (editorSDK, appToken) => {
    const BLOG_APP_ID = '14bcded7-0066-7c35-14d7-466cb3f09103'
    const FORUM_APP_ID = '14724f35-6794-cd1a-0244-25fd138f9242'
    const FF_APP_ID = '14ebe801-d78a-daa9-c9e5-0286a891e46f'
    const ABOUT_APP_ID = '14dbef06-cc42-5583-32a7-3abd44da4908'
    const SOCIAL_GROUPS_APP_ID = '148c2287-c669-d849-d153-463c7486a694'
    const EVENTS_APP_ID = '140603ad-af8d-84a5-2c80-a0f60cb47351'

    const isAppInstalled = async (appDefinitionId) => {
        return await editorSDK.document.tpa.isApplicationInstalled(appToken, {appDefinitionId})
    }

    return {
        hasSocialPages: async () => {
            return enforceSequentiality(async () => {
                const appRouters = await editorSDK.routers.getAll(appToken)
                const socialPages = appRouters.filter(r => r.config.type === 'public' && r.config.patterns && Object.keys(r.config.patterns).length > 0)
                const blogInstalled = await isAppInstalled(BLOG_APP_ID)
                const forumInstalled = await isAppInstalled(FORUM_APP_ID)
                const ffInstalled = await isAppInstalled(FF_APP_ID)
                const aboutInstalled = await isAppInstalled(ABOUT_APP_ID)
                const socialGroupsInstalled = await isAppInstalled(SOCIAL_GROUPS_APP_ID)
                const eventsInstalled = await isAppInstalled(EVENTS_APP_ID)
                return blogInstalled || forumInstalled || ffInstalled || aboutInstalled || socialGroupsInstalled || eventsInstalled || socialPages.length > 0
            })
        },
        deletePage: async pageData => {
            return enforceSequentiality(async () => {
                const myAccountPageRef = await pages.getMainMembersPageRef({ editorSDK })
                await pages.remove({
                    editorSDK,
                    pageRef: {id: pageData.id},
                    shouldShowEditorRemovePanel: false,
                    pageToNavigateAfterRemove: myAccountPageRef
                })
            })
        },
        removePage: async pageData => {
            return enforceSequentiality(async () => {
                await memberPages.removePage({editorSDK, appToken, options: pageData, removeDependantApps: false})
            })
        },
        uninstall: () => {
            return enforceSequentiality(() => toMonitored('uninstall', async () => {
                await membersLogic.uninstall(editorSDK, appToken)
            }))
        },
        getRouterDataByPage: pageRef => {
            return enforceSequentiality(async () => await routers.getPageRouterData(editorSDK, appToken, pageRef))
        },
        getAllRouters: () => {
            return enforceSequentiality(async () => await routers.getAll(editorSDK, appToken))
        },
        updatePageDataInRouter: (pageRef, dataFieldToUpdate, updatedData) => {
            return enforceSequentiality(async () => await routers.updatePageData(editorSDK, appToken, pageRef, dataFieldToUpdate, updatedData))
        },
        saveInnerRoute: (newInnerRoute, pageRef, pageRouterData) => {
            return enforceSequentiality(async () => {
                let savedInnerRoute = await routers.changePagePattern(editorSDK, appToken, pageRef, newInnerRoute)
                if (savedInnerRoute && savedInnerRoute[0] === '/') {
                    savedInnerRoute = savedInnerRoute.substr(1)
                }

                await menus.updateMenuItemInAllMenus({editorSDK, appToken, pageRouterData: pageRouterData, updatedData: {link: {innerRoute: savedInnerRoute}}})
                history.addWithDelay(editorSDK, appToken, 'Members update uri seo')
            })
        },

        /* Pages */
        updatePageData: async ({pageRef, pageData}) => {
            enforceSequentiality(async () => await pages.updatePageData({editorSDK, appToken, pageRef, pageData}))
        },
        savePasswordPermissions: async (pageRef, privacy, plainPassword) => {
            if (privacy === PERMISSION_STATES.PASSWORD && plainPassword) {
                await pages.updatePageData({editorSDK, appToken, pageRef, pageData: {pageSecurity: {requireLogin: false}}})
                enforceSequentiality(async () => await pages.updatePagePassword({editorSDK, appToken, pageRef, plainPassword}))
            } else {
                const shouldRequireLogin = !isEqual_(privacy, PERMISSION_STATES.NONE)
                await pages.updatePageData({editorSDK, appToken, pageRef, pageData: {pageSecurity: {requireLogin: shouldRequireLogin}}})
                enforceSequentiality(async () => await pages.removePagePassword({editorSDK, appToken, pageRef}))
            }
        },
        /* PageInfo */
        renamePage: async (pageName, pageRef, pageRouterData) => {
            enforceSequentiality(async () => {
                const PpageRename = pages.rename({
                    editorSDK,
                    appToken,
                    pageRef,
                    title: pageName
                })
                const PmenuUpdateItem = menus.updateMenuItemInAllMenus({
                    editorSDK,
                    appToken,
                    pageRouterData,
                    updatedData: {label: pageName}
                })
                const ProuterDataUpdate = routers.updatePageData(editorSDK, appToken, pageRef, 'title', pageName)
                const ProuterSeoTitleUpdate = routers.updatePageData(editorSDK, appToken, pageRef, 'seoData', {title: pageName})

                await Promise.all([PpageRename, PmenuUpdateItem, ProuterDataUpdate, ProuterSeoTitleUpdate])
                history.add(editorSDK, appToken, 'Members page rename')
            })
        }
    }
}
