const MEMBERS_PAGES_PANEL_HELP_ID = '7710f007-cbdb-4b77-bb8b-fd4d65424ab1'
const CLASSIC_EDITOR_MAIN_SECTION_WIDTH = 980

const CONTROLLER_COMP_DEF = {
    type: 'Component',
    skin: 'platform.components.skins.controllerSkin',
    layout: {
        width: 40,
        height: 40,
        x: 20,
        y: 15,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    },
    componentType: 'platform.components.AppController',
    data: {
        type: 'AppController',
        applicationId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
        name: 'Members controller',
        controllerType: 'members'
    },
    metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false
    },
    style: {
        type: 'TopLevelStyle',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        },
        style: {
            groups: {},
            properties: {
                'alpha-bg': '1',
                'alpha -bgh': '1',
                'alpha - brd': '1',
                'alpha - brdh': '1',
                'alpha - txt': '1',
                'alpha - txth': '1',
                bg: '#3D9BE9',
                bgh: '#2B689C',
                'boxShadowToggleOn -shd': 'false',
                brd: '#2B689C',
                brdh: '#3D9BE9',
                brw: '0px',
                fnt: 'normal normal normal 14px/1.4em raleway',
                rd: '20px',
                shd: '0 1px 4px rgba(0, 0, 0, 0.6);',
                txt: '#FFFFFF',
                txth: '#FFFFFF'
            },
            propertiesSource: {
                bg: 'value',
                bgh: 'value',
                brd: 'value',
                brdh: 'value',
                brw: 'value',
                fnt: 'value',
                rd: 'value',
                shd: 'value',
                txt: 'value',
                txth: 'value'
            }
        },
        componentClassName: 'platform.components.AppController',
        skin: 'platform.components.skins.controllerSkin'
    }
}

const LOGIN_COMP_DEF = {
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
    layout: {
        width: 120,
        height: 40,
        x: 850,
        y: 24,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    },
    componentType: 'wysiwyg.viewer.components.LoginSocialBar',
    data: {
        type: 'LoginSocialBar',
        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
        loggedInMember: 'avatarOnly',
        loggedOutText: 'Log In',
        showLoggedInText: false,
        loggedInText: 'Hello',
        logOutText: 'Log Out',
        menuItemsRef: null,
        iconItemsRef: null,
        language: 'en',
        role: 'members_login'
    },
    props: {
        type: 'LoginSocialBarProperties',
        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
        buttonsDirection: 'ltr',
        iconSize: 26,
        dropDownTextAlignment: 'left',
        buttonsAlignment: 'right'
    },
    style: {
        type: 'TopLevelStyle',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        },
        style: {
            properties: {
                'alpha-bg': '0',
                'alpha-brd': '1',
                'alpha-brd-dd': '0.2',
                'badge-bg': '#E21C21',
                'badge-txt': 'color_11',
                bg: 'transparent',
                'bg-dd': 'color_11',
                'boxShadowToggleOn-shd': 'false',
                'boxShadowToggleOn-shd-dd': 'false',
                brd: 'color_15',
                'brd-dd': 'color_15',
                brw: '0',
                'brw-dd': '1px',
                fillcolor: 'color_18',
                fnt: 'font_8',
                'fnt-size-dd': '15px',
                rd: '0px',
                'rd-dd': '0px',
                shd: '0 0 0 transparent',
                'shd-dd': '0 0 0 transparent',
                txt: 'color_18',
                'txt-dd': 'color_15',
                txth: 'color_17',
                'txth-dd': 'color_14',
                'txt-slct-dd': 'color_18'
            },
            propertiesSource: {},
            groups: {}
        },
        componentClassName: 'wysiwyg.viewer.components.LoginSocialBar',
        pageId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin'
    }
}

const LOGIN_COMP_DEF_ECOM = {
    layout: {
        x: 810,
        y: 24,
        fixedPosition: false,
        width: 120,
        height: 40,
        scale: 1,
        rotationInDegrees: 0
    },
    componentType: 'wysiwyg.viewer.components.LoginSocialBar',
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
    data: {
        type: 'LoginSocialBar',
        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
        loggedInMember: 'avatarOnly',
        loggedOutText: 'Log In',
        showLoggedInText: false,
        loggedInText: 'Hello',
        logOutText: 'Log Out',
        menuItemsRef: null,
        iconItemsRef: null,
        language: 'en',
        role: 'members_login'
    },
    props: {
        type: 'LoginSocialBarProperties',
        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
        buttonsDirection: 'ltr',
        iconSize: 26,
        dropDownTextAlignment: 'left',
        buttonsAlignment: 'right'
    },
    style: {
        type: 'TopLevelStyle',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        },
        style: {
            properties: {
                'alpha-bg': '0',
                'alpha-brd': '1',
                'alpha-brd-dd': '0.2',
                'badge-bg': '#E21C21',
                'badge-txt': 'color_11',
                bg: 'transparent',
                'bg-dd': 'color_11',
                'boxShadowToggleOn-shd': 'false',
                'boxShadowToggleOn-shd-dd': 'false',
                brd: 'color_15',
                'brd-dd': 'color_15',
                brw: '0',
                'brw-dd': '1px',
                fillcolor: 'color_18',
                fnt: 'font_8',
                'fnt-size-dd': '15px',
                rd: '0px',
                'rd-dd': '0px',
                shd: '0 0 0 transparent',
                'shd-dd': '0 0 0 transparent',
                txt: 'color_18',
                'txt-dd': 'color_15',
                txth: 'color_17',
                'txth-dd': 'color_14',
                'txt-slct-dd': 'color_18'
            },
            propertiesSource: {},
            groups: {}
        },
        componentClassName: 'wysiwyg.viewer.components.LoginSocialBar',
        pageId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin'
    },
    connections: {
        type: 'ConnectionList',
        items: [{type: 'WixCodeConnectionItem', role: 'loginSocialBar1'}, {
            type: 'ConnectionItem',
            role: 'members_login',
            config: '{}',
            controllerId: 'dataItem-j6gb4xg1'
        }],
        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false}
    },
    activeModes: {'masterPage-mode-j6gb4xmc': {modeType: 'SHOW_ON_SOME_PAGES'}}
}

const MENU_COMP_DEF = {
    type: 'Component',
    layout: {
        width: 250,
        height: 94,
        x: 20,
        y: 275,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    },
    componentType: 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
    data: {
        type: 'CustomMenuDataRef',
        metaData: {isPreset: true, schemaVersion: '1.0', isHidden: false},
        menuRef: '#MEMBERS_SUB_MENU',
        role: 'members_menu'
    },
    props: {
        type: 'VerticalMenuProperties',
        metaData: {schemaVersion: '1.0'},
        itemsAlignment: 'left',
        subMenuOpenSide: 'right',
        originalHeight: 210,
        menuItemHeight: 40
    },
    style: {
        type: 'TopLevelStyle',
        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
        style: {
            properties: {
                SKINS_bgSubmenu: '#FFFFFF',
                SKINS_fntSubmenu: 'font_8',
                SKINS_submenuBR: '0',
                SKINS_submenuMargin: '0',
                'alpha-SKINS_bgSubmenu': '1',
                'alpha-bg': '1',
                'alpha-bgh': '1',
                'alpha-bgs': '1',
                'alpha-brd': '0.2',
                'alpha-sep': '1',
                bg: 'color_11',
                bgh: 'color_11',
                bgs: 'color_11',
                'boxShadowToggleOn-shd': 'false',
                brd: 'color_15',
                brw: '1px',
                fnt: 'font_8',
                rd: '0',
                sep: 'color_15',
                sepw: '0',
                shd: '0',
                textSpacing: '30',
                txt: 'color_15',
                txth: 'color_14',
                txts: 'color_18'
            },
            propertiesSource: {
                SKINS_bgSubmenu: 'theme',
                SKINS_fntSubmenu: 'theme',
                SKINS_submenuBR: 'value',
                SKINS_submenuMargin: 'value',
                'alpha-SKINS_bgSubmenu': 'value',
                'alpha-bg': 'value',
                'alpha-bgh': 'value',
                'alpha-bgs': 'value',
                'alpha-brd': 'value',
                'alpha-sep': 'value',
                bg: 'theme',
                bgh: 'theme',
                bgs: 'theme',
                'boxShadowToggleOn-shd': 'value',
                brd: 'theme',
                brw: 'value',
                fnt: 'theme',
                rd: 'value',
                sep: 'theme',
                sepw: 'value',
                shd: 'value',
                textSpacing: 'value',
                txt: 'theme',
                txth: 'value',
                txts: 'value'
            },
            groups: {}
        },
        componentClassName: 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
        pageId: '',
        compId: 'i765dc0m',
        styleType: 'custom',
        skin: 'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin'
    },
    id: 'i765dc0m'
}

const MENU_COMP_DEF_HORIZONTAL = {
    ...MENU_COMP_DEF,
    componentType: 'wysiwyg.viewer.components.menus.DropDownMenu',
    layout: {
        width: 956,
        height: 40,
        x: 12,
        y: 250,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    },
    style: {
        type: 'TopLevelStyle',
        style: {
            // Theme or direct values
            properties: {
                'boxShadowToggleOn-shd': 'true',
                'alpha-bgDrop': '1',
                'alpha-brdh': '1',
                'alpha-brd': '0',
                'alpha-brds': '1',
                bgDrop: 'color_11',
                bg: 'color_11',
                brd: 'color_15',
                brdh: 'color_17',
                brds: 'color_18',
                fnt: 'font_8',
                menuTotalBordersX: '0',
                pad: '10',
                rd: '0px',
                shd: '0px 1px 4px 0px rgba(0,0,0,0.2)',
                txt: 'color_15',
                txth: 'color_17',
                txts: 'color_18',
            },
            // Specifies whether value is taken from theme or directly
            propertiesSource: {
                'boxShadowToggleOn-shd': 'value',
                'alpha-bgDrop': 'value',
                'alpha-brd': 'value',
                'alpha-brdh': 'value',
                'alpha-brds': 'value',
                bgDrop: 'theme',
                bg: 'theme',
                brd: 'theme',
                brdh: 'theme',
                brds: 'theme',
                fnt: 'theme',
                menuTotalBordersX: 'value',
                pad: 'value',
                rd: 'value',
                shd: 'value',
                txt: 'theme',
                txth: 'theme',
                txts: 'theme'
            }
        },
        styleType: 'custom',
        componentClassName: 'wysiwyg.viewer.components.menus.DropDownMenu',
        skin: 'wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin'
    },
    props: {
        type: 'HorizontalMenuProperties',
        metaData: {schemaVersion: '1.0'},
        alignButtons: 'left',
        alignText: 'left',
        sameWidthButtons: false,
        stretchButtonsToMenuWidth: false,
        moreButtonLabel: 'More',
        moreItemHeight: 15
    },
}

const MENU_COMP_DEF_RTL = {
    ...MENU_COMP_DEF,
    props: {
        ...MENU_COMP_DEF.props,
        itemsAlignment: 'right',
    }
}

const MENU_COMP_DEF_HORIZONTAL_RTL = {
    ...MENU_COMP_DEF_HORIZONTAL,
    props: {
        ...MENU_COMP_DEF_HORIZONTAL.props,
        itemsAlignment: 'right',
        alignButtons: 'right',
        alignText: 'right',
    }
}

const MENU_IDS = {
    SUB_MENU_ID: 'MEMBERS_SUB_MENU',
    LOGIN_MENU_ID: 'MEMBERS_LOGIN_MENU',
    LOGIN_ICONS_MENU_ID: 'MEMBERS_LOGIN_ICONS_MENU'
}

const SOSP_CONTAINER = {
    componentType: 'wysiwyg.viewer.components.SiteRegionContainer',
    layout: {
        x: 0,
        y: 0,
        fixedPosition: false,
        width: 291,
        height: 451,
        scale: 1,
        rotationInDegrees: 0
    },
    type: 'Container',
    components: [],
    style: {
        type: 'TopLevelStyle',
        id: 'c1',
        style: {
            properties: {
                'alpha-bg': 0,
                'alpha-brd': '1',
                bg: 'color_1',
                'boxShadowToggleOn-shd': false,
                brd: 'color_15',
                brw: '0',
                rd: '0px',
                shd: '0px 0px 0px 0px rgba(0,0,0,0)'
            },
            propertiesSource: {
                bg: 'theme',
                brd: 'theme',
                brw: 'value',
                rd: 'value',
                shd: 'value'
            },
            groups: {}
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
    },
    activeModes: {}
}

const SOSP_CONTAINER_HORIZONTAL = {
    ...SOSP_CONTAINER,
    layout: {
        x: 0,
        y: 0,
        fixedPosition: false,
        width: CLASSIC_EDITOR_MAIN_SECTION_WIDTH,
        height: 290,
        scale: 1,
        rotationInDegrees: 0
    },
    style: {
        type: 'TopLevelStyle',
        style: {
            properties: {
                'alpha-bg': 1,
                'alpha-brd': '1',
                bg: 'color_11',
                'boxShadowToggleOn-shd': false,
                brd: 'color_15',
                brw: '0',
                rd: '0px',
                shd: '0px 0px 0px 0px rgba(0,0,0,0)'
            },
            propertiesSource: {
                'alpha-bg': 'value',
                'alpha-brd': 'value',
                bg: 'theme',
                brd: 'theme',
                brw: 'value',
                rd: 'value',
                shd: 'value',
                'boxShadowToggleOn-shd': 'value'
            },
            groups: {}
        },
        componentClassName: 'wysiwyg.viewer.components.menus.DropDownMenu',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
    },
}

const ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd'

const ROUTERS = {
    PRIVATE: {
        prefix: 'account',
        group: 'members',
        config: {
            type: 'private'
        },
        patterns: {}
    },
    SOCIAL: {
        prefix: 'profile',
        group: 'members',
        config: {
            type: 'public'
        },
        patterns: {}
    }
}

const SECTION_DEFAULT_LAYOUT = {
    x: 300,
    y: 60,
    width: 660,
    height: 600
}

const SECTION_DEFAULT_LAYOUT_HORIZONTAL = {
    x: 0,
    y: 290,
    width: CLASSIC_EDITOR_MAIN_SECTION_WIDTH,
    height: 600
}

const MEMBERS_PAGES_GROUP_NAME = 'members_pages_group'
const CONTROLLER_COMP_CUSTOM_ID = 'CONTROLLER_COMP_CUSTOM_ID'
const SOSP_CONTAINER_CUSTOM_ID = 'SOSP_CONTAINER_CUSTOM_ID'
const RENAME_ROUTER_PANEL_HEIGHT = 395
const DEFAULT_MENU_ORDER = 2
const BLANK_PAGE_MENU_ORDER = 4
const PROFILE_WIDGET_APP = {
    appDefinitionId: '14ce28f7-7eb0-3745-22f8-074b0e2401fb'
}
const ALL_MEMBERS_APP_DEF_ID = '1518f596-5744-ae33-0524-4fe42c3ee64e'

const PW_RESPONSIVE_LAYOUT = {
    type: 'LayoutData',
    componentLayouts: [{
        type: 'ComponentLayout',
        height: {type: 'px', value: 250},
        width: {type: 'auto'}
    }],
    itemLayouts: [{
        type: 'GridItemLayout',
        gridArea: {
            rowStart: 1,
            columnStart: 1,
            rowEnd: 2,
            columnEnd: 2,
        },
        justifySelf: 'stretch',
        alignSelf: 'stretch',
        margins: {
            left: {type: 'px', value: 0},
            top: {type: 'px', value: 0},
            right: {type: 'px', value: 0},
            bottom: {type: 'px', value: 0}
        },
    }],
    containerLayouts: []
}

const PW_SIDEBAR_SOSP_TOP_MARGIN = 60
const PW_SIDEBAR_SOSP_BOTTOM_MARGIN = 30

const PW_VERTICAL_LAYOUT = {
    x: 20,
    y: PW_SIDEBAR_SOSP_TOP_MARGIN,
    width: 250,
    height: 183
}

const PW_HORIZONTAL_LAYOUT = {
    x: 0,
    y: 0,
    width: CLASSIC_EDITOR_MAIN_SECTION_WIDTH,
    height: 250
}

const PERMISSION_STATES = {
    NONE: 'none',
    PASSWORD: 'password',
    MEMBERS: 'members'
}

const NEW_PAGE_ROUTE = 'new-page'
const SANTA_MEMBERS_APP_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'
const MY_ACCOUNT_APP_DEF_ID = '14cffd81-5215-0a7f-22f8-074b0e2401fb'

const getMyAccountInstallDefinition = (origin = {}) => Object.assign({}, SECTION_DEFAULT_LAYOUT, {
    appDefinitionId: MY_ACCOUNT_APP_DEF_ID,
    pageId: 'member_info',
    menuOrder: 3,
    social: false,
    shouldNavigate: origin.initiator === 'EDITOR',
    showInLoginMenu: true,
    showInMemberMenu: true,
    urlOverride: 'my-account',
    method: 'addApplication',
})

const UNDELETABLE_PAGES = ['member_info', 'notifications_app', 'settings']

export {
    MEMBERS_PAGES_PANEL_HELP_ID,
    CLASSIC_EDITOR_MAIN_SECTION_WIDTH,
    CONTROLLER_COMP_CUSTOM_ID,
    CONTROLLER_COMP_DEF,
    DEFAULT_MENU_ORDER,
    ECOM_APP_DEF_ID,
    LOGIN_COMP_DEF_ECOM,
    LOGIN_COMP_DEF,
    MEMBERS_PAGES_GROUP_NAME,
    MENU_COMP_DEF,
    MENU_COMP_DEF_HORIZONTAL,
    MENU_COMP_DEF_RTL,
    MENU_COMP_DEF_HORIZONTAL_RTL,
    MENU_IDS,
    NEW_PAGE_ROUTE,
    PERMISSION_STATES,
    PW_SIDEBAR_SOSP_TOP_MARGIN,
    PW_SIDEBAR_SOSP_BOTTOM_MARGIN,
    PROFILE_WIDGET_APP,
    PW_VERTICAL_LAYOUT,
    PW_HORIZONTAL_LAYOUT,
    PW_RESPONSIVE_LAYOUT,
    RENAME_ROUTER_PANEL_HEIGHT,
    ROUTERS,
    SANTA_MEMBERS_APP_ID,
    MY_ACCOUNT_APP_DEF_ID,
    SECTION_DEFAULT_LAYOUT,
    SECTION_DEFAULT_LAYOUT_HORIZONTAL,
    SOSP_CONTAINER_CUSTOM_ID,
    SOSP_CONTAINER,
    SOSP_CONTAINER_HORIZONTAL,
    BLANK_PAGE_MENU_ORDER,
    getMyAccountInstallDefinition,
    ALL_MEMBERS_APP_DEF_ID,
    UNDELETABLE_PAGES
}
